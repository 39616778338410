import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const CallBox = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulDoctor {
        edges {
          node {
            picture {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 128) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const doctors = data.allContentfulDoctor.edges
  const number = location ? location.phone : null

  if (number) {

    return (
        <div className="font-sans bg-navy-500 text-white text-sm fixed bottom-0 right-0 w-full md:hidden">
          <div className="py-3 pl-3 pr-6  flex items-center justify-between container lg:py-5 lg:mx-auto lg:px-0">
            <div className="flex items-center justify-betwen font-medium w-full">
              <div className="flex items-center text-navy-300 w-full justify-between">
              <a href={'tel:' + number}>
                <div className="radial-gradient-green rounded-full shadow ">
                  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z" fill="white" />
                  </svg>
                </div>
              </a>
              <Link to="/doctors">
                <div className="flex items-center">
                  <div className="flex items-center ml-4 px-2">{doctors.map(({ node: doctor }) => {
                    return <Img key={doctor.picture.id} className="w-12 rounded-full -ml-4 border-2 border-white" fluid={doctor.picture.localFile.childImageSharp.fluid} />
                  })}</div>
                  <p className="text-navy-50 tracking-wide leading-tight w-10">Know your doctors</p>
                </div>
              </Link>
              </div>
            </div>
          </div>
        </div>
      
    )
  } else {
    return (
      <div className="font-sans bg-navy-500 text-white text-sm fixed bottom-0 right-0   w-full md:hidden">
        <div className="py-3 pl-3 pr-6  flex items-center justify-between container lg:py-5 lg:mx-auto lg:px-0">
          <div className="flex items-center justify-betwen font-medium w-full">
            <div className="flex items-center text-navy-300 w-full justify-between">
              <Link to="/locations" aria-label='locations'>
                <div className="radial-gradient-green rounded-full shadow ">
                  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z" fill="white" />
                  </svg>
                </div>
              </Link>
              <Link to="/doctors" aria-label='doctors'>
                <div className="flex items-center">
                  <div className="flex items-center ml-4 px-2">{doctors.map(({ node: doctor }) => {
                    return <Img key={doctor.picture.id} className="w-12 rounded-full -ml-4 border-2 border-white" fluid={doctor.picture.localFile.childImageSharp.fluid} />
                  })}</div>
                  <p className="text-navy-50 tracking-wide leading-tight w-10">Know your doctors</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CallBox

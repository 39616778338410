/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"








function SEO({ schemaData, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const schemaOrgJSONLD = [];

  let article = schemaData ? schemaData.type : null
  let location = schemaData ? schemaData.type : null

  if (true) {
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: 'http://www.umeyecare.com',
      name: 'U & M Family Eyecare',
    })
  }
  if (location === 'location') {
    schemaOrgJSONLD.push(
      {
        "@context": "https://schema.org",
        "@type": "Optician",
        "name": "U & M Family Eyecare " + schemaData.name,
        "image": "images.ctfassets.net/pm9nc6fj35ph/1KDYpfYoYezbKDNCN4g8AM/bdbe47954a67c61c0bfd13df5db97167/logo.png",
        "@id": "123467bcvbcv2cv3b1cv32b1cv23b1c3v2b1vc32b1c3v2b1c23vb1c321df2g1df3g213df89",
        "url": "http://www.umeyecare.com" + schemaData.url,
        "telephone": schemaData.phone,
        "priceRange": "$",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": schemaData.address,
          "addressLocality": schemaData.city,
          "addressRegion": schemaData.state,
          "postalCode": schemaData.zipCode,
          "addressCountry": "US"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": schemaData.averageRating,
          "reviewCount": schemaData.reviewsCount
        },
        "review": schemaData.reviews ? schemaData.reviews.map((review) => {
          return{
            "@type": "Review",
              "reviewRating": {
              "@type": "Rating",
                "ratingValue": review.rating,
                "bestRating": review.rating
            },
            "author": {
              "@type": "Person",
              "name": review.name
            }
          }
        }) : null,
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": schemaData.location.lat,
          "longitude": schemaData.location.lon
        },
        "openingHoursSpecification": [{
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Wednesday",
            "Friday",
            "Saturday"
          ],
          "opens": "09:00",
          "closes": "18:00"
        }, {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Tuesday",
            "Thursday"
          ],
          "opens": "10:00",
          "closes": "19:00"
        }]
      }
    )
  }
  if (article === 'article') {
    schemaOrgJSONLD.push(
      {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "http://localhost:8000/articles/common-vision-problems"
        },
        "headline": schemaData.title,
        "description": schemaData.title,
        "image": {
          "@type": "ImageObject",
          "url": schemaData.cover.localFile.url,
          "width": 1280,
          "height": 720
        },
        "author": {
          "@type": "Organization",
          "name": "U & M Family Eyecare"
        },
        "publisher": {
          "@type": "Organization",
          "name": "U & M Family Eyecare",
          "logo": {
            "@type": "ImageObject",
            "url": "images.ctfassets.net/pm9nc6fj35ph/1KDYpfYoYezbKDNCN4g8AM/bdbe47954a67c61c0bfd13df5db97167/logo.png",
            "width": 200,
            "height": 200
          }
        },
        "datePublished": "2019-09-11",
        "dateModified": "2019-09-18"
      }
    )
  }

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import CallBox from "../components/callbox"

import Header from "./header"
import "./layout.css"

const Layout = ({ umLocation, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      },
      allContentfulLocation {
        edges {
          node {
            addressPrimaryLine
            city
            phone
            state
            zipCode
            name
            id
            phone
            node_locale
            url
            address
            location {
              lon
              lat
            }
          }
        }
      }
    }
  `)
  const locations = data.allContentfulLocation.edges

  return (
    <>
      <Header umLocation={umLocation} siteTitle={data.site.siteMetadata.title} />
      <div className="relative ">
        <main style={{minHeight: 800}} className="font-sans ">{children}</main>
        <footer className="text-center bg-navy-500 w-full p-4">
          <CallBox location={umLocation}/>
          <div className="flex flex-wrap px-4 md:mt-4 justify-around md:mb-2">
            {locations.map(({ node: location }, i) => {
              return <div key={location.id} className={`w-64 px-1 text-left text-white block my-4 ${i === locations.length - 1 ? ' pb-20 md:pb-0' : ''}`}>
                  <Link to={'/' + location.url} aria-label={location.name + ' U & M Family Eyecare'} className="font-bold">{location.name}</Link>
                  <div className="text-sm">{' U & M Family Eye Care ' + location.name}</div>
                  <div>{location.addressPrimaryLine}</div>
                  <div>{location.city + ', '}{location.state + ', '}{location.zipCode}</div>
                  <a href={'tel:'+location.phone}>{location.phone}</a>
              </div>
            })}
          </div>
          <p className="hidden mt-4 md:block text-white"> © {new Date().getFullYear()}, U & M Family Eyecare </p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
